
import { getRoleList } from "./role";

// 更新角色列表
export const updateStoreRoleList = (projectId) => {
    return getRoleList({
        project_id: projectId,
        pageSize: 0,
        current: 1
    }).then((result) => {
        console.info(result);
        const roles = result.data?.roles || [];
        if (roles && roles.length > 0) {
            return roles.map((item) => {
                return {
                    ...item,
                    label: item.name,
                    value: item.role_id,
                }
            });
        }
        return roles;
    })
        .catch((err) => {
            console.error(err);
        });
}