

const permission = {
  mounted(el, binding) {
    console.log(el, binding, 'permission');
  },
}

export function setupDirectives(app) {
  app.directive('permission', permission)
}
