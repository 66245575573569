import axios from 'axios'
import { getStorage, deleteStorage } from "@/utils/localStorage"
import router from '@/router/index'
import { _TOKEN } from '@/api/login'

axios.defaults.withCredentials = true;
//创建axios的一个实例 
var instance = axios.create({
    // baseURL: import.meta.env.VITE_APP_URL, //接口统一域名
    // timeout: 6000, //设置超时
    headers: {
        'Content-Type': "application/json",
    }
})

//请求拦截器 
instance.interceptors.request.use((config) => {
    // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
    const token = getStorage('token');
    if (token) {
        config.headers.token = token;
    }
    return config;
}, (error) =>
    // 对请求错误做些什么
    Promise.reject(error));

//响应拦截器
instance.interceptors.response.use((response) => {
    // hideLoading()
    //响应成功
    if (response.data.status === 302) {
        deleteStorage(_TOKEN)
        router.replace('/login')
    }
    return response.data;
}, (error) => {
    //响应错误
    if (error.response && error.response.status) {
        const status = error.response.status
        let message = '';
        switch (status) {
            case 301:
            case 302: {
                deleteStorage(_TOKEN)
                router.replace('/login')
                break;
            }
            case 400:
                message = '请求错误';
                // TODO：目前业务逻辑code
                let code = error.response.data.code
                switch (code) {
                    case 4201:
                    case 4202:
                    case 4204:
                    case 4207:
                        return '登录失败，请重试'
                    case 4203:
                        return '验证码错误'
                    case 4205:
                    case 4206:
                        return '用户或密码错误'
                    case 4208:
                        return '更新密码失败，请重试'
                    case 4209:
                        return '发送邮箱验证码失败'
                    case 4210:
                        return '用户名或邮箱重复'
                    case 4211:
                        return '项目不存在'
                    case 4212:
                        return '密码必须包含大小写字母、数字、特殊字符'
                    case 4018:
                        return '权限限制'
                    case 5207:
                        return '场景数量限制'
                    case 5208:
                        return '主场景已存在'
                    default:
                        return '请求失败'
                }
                break;
            case 401:
                // token过期 重定向到登录
                deleteStorage(_TOKEN)
                router.replace('/login')
            case 403:
                message = '请求错误';
                break;
            case 404:
                message = '请求地址出错';
                break;
            case 408:
                message = '请求超时';
                break;
            case 500:
                message = '服务器内部错误!';
                break;
            case 501:
                message = '服务未实现!';
                break;
            case 502:
                message = '网关错误!';
                break;
            case 503:
                message = '服务不可用!';
                break;
            case 504:
                message = '网关超时!';
                break;
            case 505:
                message = 'HTTP版本不受支持';
                break;
            default:
                message = '请求失败'
        }
        console.info(message);
    }
    return Promise.resolve(error);
});

/**
 * @param {String} method  请求的方法：get、post、delete、put
 * @param {String} url     请求的url:
 * @param {Object} data    请求的参数
 * @param {Object} config  请求的配置
 * @returns {Promise}     返回一个promise对象，其实就相当于axios请求数据的返回值
 */

export const request = ({
    method,
    url,
    data,
    config
}) => {
    method = method.toLowerCase();
    if (method == 'post') {
        return instance.post(url, data, { ...config })
    } else if (method == 'get') {
        return instance.get(url, {
            params: data,
            ...config
        })
    } else if (method == 'delete') {
        return instance.delete(url, {
            data,
            ...config
        },)
    } else if (method == 'put') {
        return instance.put(url, data, { ...config })
    } else {
        console.error('未知的method' + method)
        return false
    }
}
