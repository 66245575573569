<template>
  <div>
    <template v-for="item in list" :key="item.path">
      <t-menu-item
        v-if="!item.children || !item.children.length"
        :name="item.path"
        :value="item.path"
        :to="item.path"
        :title="item.title"
      >
        <template #icon>
          <icon :name="item.icon" :url="iconUrl" />
        </template>
        {{ item.title }}
      </t-menu-item>

      <t-submenu
        v-else
        :name="item.path"
        :value="item.path"
        :title="item.title"
      >
        <menu-content v-if="item.children" :nav-data="item.children" />
      </t-submenu>
    </template>
  </div>
</template>
<script setup>
import { computed } from "vue";
import { Icon } from "tdesign-icons-vue-next";

const iconUrl = "/icon/icon.js";

const props = defineProps({
  navData: {
    type: Array,
    default: () => [],
  },
});

const list = computed(() => {
  const { navData } = props;
  return navData;
});
</script>
<style lang="less" scoped>
.t-menu__item:hover:not(.t-is-active):not(.t-is-opened):not(.t-is-disabled) {
  background: linear-gradient(90deg, #333b4d 0%, #293041 86.53%);
  --ripple-color: none !important;
  color: white;
  .t-icon {
    fill: url(#paint0_linear_694_15128);
    stroke: url(#paint0_linear_694_15128);
  }
}
.t-default-menu:not(.t-menu--dark) .t-menu__item.t-is-active:not(.t-is-opened) {
  background: rgba(11, 67, 212, 1);
  color: white;
  .t-icon {
    color: white;
    stroke: white;
  }
}
</style>