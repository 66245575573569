<template>
  <div>
    <div class="second-title">{{ pageStatus.lang.userCenter.userInfo }}</div>
    <t-form class="user-detail">
      <t-form-item
        :label="pageStatus.lang.userCenter.userName"
        name="username"
        initial-data="TDesign"
      >
        {{ userInfo.username }}
      </t-form-item>
      <t-form-item
        :label="pageStatus.lang.userCenter.userEmail"
        name="email"
        initial-data="123456"
      >
        {{ userInfo.email }}
      </t-form-item>
      <!-- <t-form-item label="手机号码" name="phone" initial-data="['1']">
        {{ userInfo.phone }}
      </t-form-item> -->
    </t-form>
    <div v-if="showProject">
      <div class="second-title">{{ pageStatus.lang.userCenter.userInfo }}</div>
      <middle-table
        :columns="columns"
        :list="list"
        :total="total"
        :default-page-size="defaultPageSize"
        :default-current="defaultCurrent"
        :show-pagination="false"
        class="info-table"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "DashboardUserCenter",
};
</script>

<script setup>
import { computed, ref, watch } from "vue";
import { pageStatusStore } from "@/store";
import MiddleTable from "../components/manager/MiddleTable.vue";

const pageStatus = pageStatusStore();

const props = defineProps({
  showProject: {
    type: Boolean,
    default: false,
  },
  userInfo: {
    type: Object,
    default: () => {
      return {};
    },
  },
});

const userInfo = ref({
  username: props.userInfo.username,
  email: props.userInfo.email,
  // phone: "",
  role: props.userInfo.roles?.map((i) => i.name).toString(),
  project: pageStatus.project.label,
});

const columns = computed(() => [
  {
    title: pageStatus.lang.userCenter.projectName,
    colKey: "project",
  },
  {
    title: pageStatus.lang.userCenter.roleName,
    colKey: "role",
  },
]);

const list = ref([
  {
    project: userInfo.value.project,
    role: userInfo.value.role,
  },
]);
const total = ref(1);
const defaultPageSize = ref(10);
const defaultCurrent = ref(1);

watch(
  () => props.userInfo,
  (v) => {
    Object.assign(userInfo.value, v);
  }
);
</script>

<style lang="less" scoped>
.title {
  font-size: 20px;
  margin: 20px 0px;
}
.second-title {
  font-size: 16px;
  margin: 20px 0px;
}

.user-detail {
  background: rgba(255, 255, 255, 0.04);
  border: 0px;
  border-radius: 10px;
  padding: 20px;
  :deep(.t-form__label) {
    color: var(--white-white-60, rgba(255, 255, 255, 0.6));
    /* 普通字体_PingFang/小字号04_14/regular */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
}
</style>