import { request } from "@/utils/request"
import { server } from "@/config/global";

const root = server + 'api/v1/role/';


export const getRoleTreeList = (data) => {
    return request({
        url: root + "tree",
        method: "get",
        data
    })
}

export const addRole = (data) => {
    return request({
        url: root + "create",
        method: "post",
        data
    })
}

export const editRole = (data) => {
    return request({
        url: root + "edit",
        method: "post",
        data
    })
}

export const delRole = (data) => {
    return request({
        url: root + data.role_id,
        method: "delete",
        config: {
            params: data
        }
    })
}
// 获取角色列表
export const getRoleList = (data) => {
    return request({
        url: root + "list",
        method: "get",
        data
    })
}