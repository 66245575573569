import { request } from "@/utils/request"
import { server } from "@/config/global";

const root = server + 'api/v1/project';
const userRoot = server + 'api/v1/user/setting';
// 获取项目列表
export const createProject = (data) => {
    const formData = new FormData();
    formData.append('name', data.name);
    return request({
        url: root,
        method: "post",
        data: formData
    })
}
// 获取项目列表
export const getProjectList = () => {

    return request({
        url: root+ "/list",
        method: "post",
        // data: {
        //     "asc": true,
        //     // "name": "string",
        //     "page": 1,
        //     "size": 10,
        //     // "sort": "string"
        // }
    })
}
// 判断项目是否存在主场景
export const checkExistMaster = (id) => {
    return request({
        url: root + "/master/" + id,
        method: "get",
    })
}

// 获取项目发布状态
export const getProjectStatus = (id) => {
    return request({
        url: root + "/status/" + id,
        method: "get",
    })
}

// 更新项目的logo文件:id\logo_state\logo_url
export const updateLogo = (data)=>{
    const formData = new FormData();
    for(let key in data){
        formData.append(`${key}`, data[key])
    }
    return request({
        url: root,
        method: "put",
        data: formData,
    })
}

// 获取项目的用户配置，目前只有语言配置信息
export const getProjectUserSetting = ()=>{
    return request({
        url: userRoot+ '/get',
        method: "get",
    })
}

// 更新项目的用户配置，目前只有语言配置信息
export const updateProjectUserSetting = (data)=>{
    const formData = new FormData();
    for(let key in data){
        formData.append(`${key}`, data[key])
    }
    return request({
        url: userRoot,
        method: "put",
        data: formData,
    })
}
export const getProjectPermission = (data)=>{
    return request({
        url: root+ '/user/perm',
        method: "get",
        data,
    })
}