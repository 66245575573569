import { defineStore } from 'pinia';

const state = {
    mapDialogVisible: false,
    menuValue: "base",
    project: {},
    tags: [],
    types: {},
    // 当前项目是否有主场景
    disableJoint: false,
    // 当前项目主场景
    mainScene: {},
    // 角色列表
    roleList: [],
    // 语言类型
    lang: {},
    userInfo: {},
};


export const pageStatusStore = defineStore('pageStatus', {
  state: () => state,
})