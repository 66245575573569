<template>
  <div :class="`${prefix}-sidebar-layout`">
    <t-menu
      v-model:expanded="expandedValue"
      :class="`${prefix}-side-nav`"
      :theme="theme"
      :collapsed="false"
      :value="dashboardTabRoute"
      @expand="handleExpand"
    >
      <menu-content :nav-data="menu" />
    </t-menu>
    <div :class="`${prefix}-side-nav-placeholder-hidden`" />
  </div>
</template>

<script setup>
import { ref } from "vue";

import { dashboardTabRoute, dashboardRouter } from "@/router";
import { prefix } from "@/config/global";

import MenuContent from "./MenuContent.vue";

const props = defineProps({
  menu: {
    type: Array,
    default: () => [],
  },
  theme: {
    type: String,
    default: "light",
  },
});

// 获取一级节点路径，作为展开菜单集合，实现默认菜单全展开
const expandedValue = ref([]);
dashboardRouter.children.forEach((item) => {
  if (item.meta) {
    expandedValue.value.push(dashboardRouter.path + "/" + item.path);
  }
});
const handleExpand = (v) => {
  console.info(v);
};
</script>

<style lang="less" scoped>
.tdesign-starter-side-nav {
  border-radius: 10px;
}
</style>
