<template>
  <div>
    <t-table
      class="table"
      hover
      row-key="index"
      :data="list"
      :columns="columns"
      :bordered="bordered"
      cell-empty-content="-"
      resizable
    >
      <template #operations="cellParams">
        <slot name="operationsCell" :cell-params="cellParams" />
      </template>
    </t-table>
    <div v-if="showPagination">
      <t-pagination
        v-model="pagination.current"
        v-model:page-size="pagination.pageSize"
        show-jumper
        :total="pagination.total"
        @change="onPageChange"
      />
    </div>
  </div>
</template>
<script setup>
import { ref, watch } from "vue";

const props = defineProps({
  list: {
    type: Array,
    default: () => [],
  },
  columns: {
    type: Array,
    default: () => [],
  },
  total: {
    type: Number,
    default: 0,
  },
  defaultPageSize: {
    type: Number,
    default: 10,
  },
  defaultCurrent: {
    type: Number,
    default: 1,
  },
  bordered: {
    type: Boolean,
    default: false,
  },
  showPagination: {
    type: Boolean,
    default: true,
  },
});
watch(
  () => props.defaultCurrent,
  (v) => {
    pagination.value.current = v;
  }
);
watch(
  () => props.defaultPageSize,
  (v) => {
    pagination.value.pageSize = v;
  }
);
watch(
  () => props.total,
  (v) => {
    pagination.value.total = v;
  }
);
const pagination = ref({
  current: props.defaultCurrent,
  pageSize: props.defaultPageSize,
  total: props.total,
});
const emits = defineEmits(["updatePage"]);

const onPageChange = (e) => {
  emits("updatePage", e);
};
</script>
<style lang="less" scoped>
.table {
  :deep(.t-table__header) {
    tr {
      background: var(--black-black-30, rgba(0, 0, 0, 0.24));
    }
    th {
      padding: 16px;
      border-width: 0px;
      // color: var(--white-white-60, rgba(255, 255, 255, 0.60));
      /* 普通字体_PingFang/小字号04_14/regular */
      .font-td-family();
    }
    th:first-child {
      border-top-left-radius: 9px;
      border-bottom-left-radius: 9px;
    }
    th:last-child {
      border-bottom-right-radius: 9px;
      border-top-right-radius: 9px;
    }
  }
  :deep(.t-table__header:hover th:not(:last-child)) {
    border-right: 0px !important;
  }
  :deep(.t-table__header:hover th) {
    border-top: 0px !important;
  }
  :deep(.t-table__body) {
    td {
      padding: 16px 24px 16px 16px;
      height: 46px !important;
      background: rgba(0, 0, 0, 0.06);
      border-bottom: 1px solid var(--white-white-20, rgba(255, 255, 255, 0.12));
      overflow: hidden;
      color: var(--white-white, #fff);
      text-overflow: ellipsis;

      // /* 普通字体_PingFang/小字号04_14/regular */
      .font-td-family();
    }
    td:last-child {
      padding: 12px 24px 12px 16px;
    }
    tr:hover {
      background: var(--white-white-10, rgba(255, 255, 255, 0.06));
    }
  }
}

:deep(.t-pagination) {
  padding: 16px 24px;
  .t-pagination__total {
    color: rgba(255, 255, 255, 0.6);
  }
  .t-pagination__select {
    .t-select-input > .t-input__wrap > .t-input {
      padding: 8px;
    }
  }
  .t-pagination__pager {
    .t-pagination__number {
      .flex-center();
      padding: 9px 0px;
      border-radius: 6px;
      border: 1px solid rgba(255, 255, 255, 0.06);
      background: rgba(255, 255, 255, 0.08);
      color: #fff;
    }
    .t-is-current {
      border: 1px solid #2965ff;
      background: rgba(11, 67, 212, 0.18);
      color: #4e81ff;
    }
  }
  .t-pagination__jump {
    margin-left: 16px;
    color: #fff;
    background: none;
    .t-input-adornment {
      margin-left: 8px;
      .t-input-number > .t-input__wrap > .t-input {
        padding: 3px 8px;
        border-radius: 6px;
        border: 1px solid rgba(255, 255, 255, 0.12);
        background: rgba(0, 0, 0, 0.24);
        color: #fff;
      }
      .t-input-adornment__append > .t-input-adornment__text {
        color: #fff;
      }
    }
  }
}
</style>
