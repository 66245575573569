
import {getStorage} from "@/utils/localStorage"

const base = ({ commandkey, commandvalue, callback }) => {
    const params = {
        commandkey,
        commandvalue,
    };
    console.info(params, callback);
    if (window.webtoue) {
        window.ue.interface[commandkey] = callback;
        window.webtoue(JSON.stringify(params), commandkey);
    }
}

// 发布按钮触发
export const publishToUE = function (projectId, callback) {
    const commandkey = 'publish';
    base({ commandkey, commandvalue:{
        projectId,
        token: getStorage("token"),
      }, callback })
};

// 拼接按钮触发
export const jointInUE = function (projectId, callback) {
    const commandkey = 'joint';
    base({ commandkey, commandvalue:{
        projectId,
        token: getStorage("token"),
      }, callback })
};

// 
export const sendProjectToUE = function ({commandkey, projectId, projectName, callback}) {
    base({ commandkey, commandvalue:{
        projectid: projectId,
        projectname: projectName,
        token: getStorage("token"),
      }, callback })
};
// 项目切换触发
export const sendProjectToUEOnChange = function (projectId, projectName, callback) {
    const commandkey = 'changeproject';
    sendProjectToUE({commandkey, projectId, projectName, callback})
};
// 项目初始化触发
export const sendProjectToUEOnLogin = function (projectId, projectName, callback) {
    const commandkey = 'login';
    sendProjectToUE({commandkey, projectId, projectName, callback})
};

// 预览按钮触发
export const previewInUE = function (projectId, secneId, callback) {
    const commandkey = 'preview';
    base({ commandkey, commandvalue:{
        projectId,
        secneId,
        token: getStorage("token"),
      }, callback })
};
// 编辑按钮触发
export const editInUE = function (projectId, secneId, callback) {
    const commandkey = 'edit';
    base({ commandkey, commandvalue:{
        projectId,
        secneId,
        token: getStorage("token"),
      }, callback })
};
// 语言环境触发
export const updateLanguageInUE = function (type, callback) {
    const commandkey = 'language';
    base({ commandkey, commandvalue:{
        type,// 'en'、'zh-CN'
        token: getStorage("token"),
      }, callback })
};
// 跳转浏览器打开对应链接
export const openInBrowser = function (url, callback) {
    const commandkey = 'openbrowser';
    base({ commandkey, commandvalue:{
        url,
        token: getStorage("token"),
      }, callback })
};