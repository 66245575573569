//首页

export const zh_CN = {
    // logo 默认logo是否需要支持多语言版本??
    // 头部 项目名称？？
    emptyProject: {
        description: '当前无可用项目，请联系相关项目管理员或接口人。',
    },
    // 用户下拉
    userDropdown: {
        // 用户名称？？
        personCenter: '个人中心',
        authManager: '权限管理',
        projectInfo: '项目信息',
        setting: '设置',
        lang: '语言lang',
        logout: '退出登录',
    },
    // 个人中心弹窗
    userCenter: {
        title: '个人信息',
        userInfo: '用户信息', userName: '用户名称', userEmail: '登录邮箱',
        userRole: '用户角色', projectName: '项目名称', roleName: '用户角色',
    },
    // 权限管理
    authManager: {
        title: '权限管理',
        userManager: '用户管理', addRole: '新建角色',
        addUser: '新建用户', filterRole: '筛选角色', filterUserEmail: '邮箱', filterUserName: '用户名', searchContent: '请输入内容',
        userOverLimitTips: '用户数量添加已达上限',
        namePlacehold: "请输入用户名称", emailPlacehold: "请输入登录邮箱", rolePlacehold: "请选择用户角色",
        userName: '用户名称', userRole: '用户角色', userEmail: '登录邮箱', operation: '操作',
        userDetail: '用户详情', editUser: '用户编辑', confirmDelUser: '是否删除当前用户?', confirmDelRole: '是否删除当前角色?', delRoleTips: '删除当前角色，当前项目下仅有该角色的用户将自动删除，请谨慎操作。',
        roleManager: '角色管理', roleNamePlacehold: "请输入角色名称", roleDescPlacehold: '请输入角色描述',
        roleName: '角色名称', createTime: '创建时间', roleDesc: '角色描述', operation: '操作',
        roleDetail: '角色详情', rolePermission: '角色权限', rolePermissionPlacehold: '请选择角色权限',
        editRole: '编辑角色',
    },
    // 设置
    // 设置
    setting: {
        title: '设置',
        edit: '编辑', permissionTips: '暂无权限，请联系项目管理员', logoConfigTips: '暂无权限，请联系接口人',
        projectConfig: '项目配置', logoConfig: 'LOGO设置',
        cancel: '取消', save: '保存',
        default: '默认', custom: '自定义上传',
        customTips: 'LOGO推荐尺寸为248*32px，大小50KB以下，建议上传svg格式',
    },
    // 语言
    // 语言
    lang: {
        title: '语言设置',
        selectLang: '请选择系统语言',
    },
    // 项目信息弹窗
    projectInfo: {
        title: '项目信息',
        baseInfo: '基本信息', projectName: '项目名称', projectID: '项目ID', token: 'Token', validateDate: '有效期',
        projectSetting: '项目配置', settingName: '配置项', settingValue: '操作',
    },
    // 退出登录
    logout: {
        confirmLogout: '是否退出当前用户',
    },
    // 左侧菜单
    menu: {
        projectManager: '项目管理', projectScenes: '项目场景',
        resource: '资产库', model: '模型库', npc: '虚拟形象库', apparel: '服饰库', texture: '材质库', vfx: '特效库', point: '点位库'
    },
    // 右侧主面板
    dashboard: {
        /**
         * 
         * 项目场景
         * 
         */
        middleBar:
        {
            items: '个',//多少个场景
            byLastUpdateTime: '按最新修改日期', byCreateTime: '按创建时间', byName: '按名称',
            selectOrderBy: '请选择排序字段', selectTag: '选择标签', selectType: '选择分类',
            tagManager: '标签管理', publishManager: '发布管理', typeManager: '分类管理',
            pubProcessTip: '发布进度提示', tipConfirm: '知道了',
            tipContentPart1: '当前项目「发布进度」和「发布链接」可在', tipContentPart2: '设置→发布管理', tipContentPart3: '中查看',
        },
        tagManager:
        {
            add: '新建标签', searchPlacehold: '请输入标签名称',
            tagName: '标签名称', sceneCount: '场景数', npcCount: '虚拟形象数', apparelCount: '服饰数', modelCount: '模型数', textureCount: '材质数', vfxCount: '特效数', pointCount: '点位数', operation: '操作',
            editTag: '编辑标签',
            addTips: '请按行输入标签，20字以内，不支持特殊字符，单次最多允许上传100条标签',
            confirmDelTag: '是否删除当前标签?',
        },
        // 发布管理
        publishManager:
        {
            title: '项目发布管理', publishUrl: '发布链接', viewPassword: '查看密码', publishTime: '发布时间',
            prodEnv: '正式环境', lastPublishTime: '上次发布时间', copyLink: '复制链接', publishing: '发布中',
            emptyResourceTip1: '当前项目暂无可用云渲染环境，可使用',
            emptyResourceTipOr: '或',
            emptyResourceTip2: '当前项目暂无可用云渲染环境，可',
            testUrl: '体验链接', buyUrl: '跳转购买', openUrl: '打开链接',
        },
        projectScenes: {
            midPanel:
            {
                title: '项目场景', searchPlacehold: '请输入场景名称',
                publishProject: '项目发布', addScene: '新建场景', joinScene: '拼接场景', commit: '提交审核',
                withoutEnvTips: '未配置发布环境，请联系接口人购买云资源后开通', sceneOverLimitTips: '场景数量添加已达上限',
                updateTime: '更新时间', mainScene: '主场景', otherScene: '分场景',
                preview: '预览', edit: '编辑',
                copyScene: '复制场景', sceneInfo: '查看信息', editScene: '修改信息', delete: '删除',
                emptyText: "您可以通过“基础底板”和“导入场景”新建项目场景",
                emptyTitle: "欢迎来到Raydata Verse",
                searchNoRes: "搜索无结果",
            },
            add:
            {
                baseTemplate: '基础底板', scenePermission: '场景权限',
                sceneName: '场景名称', sceneJoint: '场景拼接类型', sceneTag: '场景标签', tagPlacehold: '请下拉选择或输入新标签后回车添加', cancel: '取消', confirm: '确定',
                importScene: '导入场景', coordinateSystemSelect: '坐标系选择', worldCoordinateSystem: '世界坐标系', geographicalCoordinateSystem: '地理坐标系',
                coordinateSystem: '坐标系', reupload: '重新上传', delete: '删除', coordinateTypeTip: '设置后不可修改，请谨慎选择', coordinateFileTip: '文件上传保存后不可修改，请确认文件后上传',
                offsetTip: '设置后不可修改，请确认后保存', offsetPlaceholdX: '请输入偏移X值，设置后不可修改', offsetPlaceholdZ: '请输入偏移Z值，设置后不可修改', offsetPlaceholdY: '请输入偏移Y值，设置后不可修改',
                importFile: '导入文件', client: '单机版', server: '多人模式', importPlacehold: '还未上传文件', importTips: '支持上传一个UE4.27 pak文件',
                confirmDelFile: '确认删除文件吗？', deleteFile: '删除', fileFailTip: '文件上传失败，请重新上传', fileEmptyTip: '必填项，请上传文件', roleTips: '请选择新增后场景归属角色，如未选择，则仅项目管理员拥有该场景权限',
                tagEmpty: '暂无数据', tagLengthTip: '仅支持20字以内标签', tagExistTip: '该标签已存在', offsetValue: '偏移值', coordinateSystemPlacehold: '请上传坐标系prj文件，设置后不可修改',
            },
            copy:
            {
                confirmCopy: '确认复制', confirmCopyScene: '是否复制当前场景', checkRoles: '除项目管理员，复制后的场景归属角色', copyTips: '请选择复制后场景归属角色，如未选择则仅项目管理员拥有该场景权限',
            },
            // 查看信息

            // 修改信息
            edit:
            {
                editScene: '编辑场景',
            },
            // 删除
            delete:
            {
                confirmDel: '确认删除', confirmDelScene: '是否删除当前场景？',
            },
        },
        /**
         * 
         * 模型库，部分和场景相同的未单列，虚拟形象、材质、特效和模型差别就是将模型替换为对应的名称（模型,模型、虚拟形象、材质、特效）
         * 
         */
        model: {
            midPanel:
            {
                title: '模型库',
                importRes: '导入模型',
                emptyText: '请先通过{导入模型}新建模型资产',
                searchPlacehold: '请输入模型名称',
                searchNoRes: "搜索无结果",
            },
            add:
            {
                name: '模型名称', type: '模型分类', tag: '模型标签', namePlacehold: '请输入模型名称', typePlacehold: '请选择分类', thumbnail: '缩略图',
            },
            // 修改信息
            edit:
            {
                title: '编辑模型',
            },
            delete:
            {
                confirmDel: '是否删除当前模型?',
            },
        },
        npc: {
            midPanel:
            {
                title: '虚拟形象库',
                importRes: '导入虚拟形象',
                emptyText: '请先通过{导入虚拟形象}新建虚拟形象资产',
                searchPlacehold: '请输入虚拟形象名称',
                searchNoRes: "搜索无结果",
            },
            add:
            {
                name: '虚拟形象名称', type: '虚拟形象分类', tag: '虚拟形象标签', namePlacehold: '请输入虚拟形象名称', typePlacehold: '请选择分类', thumbnail: '缩略图', properties: '使用属性', propertiesPlacehold: '请选择使用属性',
                propertyPlayer: '玩家角色', propertyNPC: 'NPC',
            },
            // 修改信息
            edit:
            {
                title: '编辑虚拟形象',
            },
            delete:
            {
                confirmDel: '是否删除当前虚拟形象?',
            },
        },
        apparel: {
            midPanel:
            {
                title: '服饰库',
                importRes: '导入服饰',
                emptyText: '请先通过{导入服饰}新建服饰资产',
                searchPlacehold: '请输入服饰名称',
                searchNoRes: "搜索无结果",
            },
            add:
            {
                name: '服饰名称', type: '服饰分类', tag: '服饰标签', namePlacehold: '请输入服饰名称', typePlacehold: '请选择分类', thumbnail: '缩略图', properties: '使用属性', propertiesPlacehold: '请选择使用属性',
                propertyPlayer: '玩家角色', propertyNPC: 'NPC',
            },
            // 修改信息
            edit:
            {
                title: '编辑服饰',
            },
            delete:
            {
                confirmDel: '是否删除当前服饰?',
            },
        },
        texture: {
            midPanel:
            {
                title: '材质库',
                importRes: '导入材质',
                emptyText: '请先通过{导入材质}新建材质资产',
                searchPlacehold: '请输入材质名称',
                searchNoRes: "搜索无结果",
            },
            add:
            {
                name: '材质名称', type: '材质分类', tag: '材质标签', namePlacehold: '请输入材质名称', typePlacehold: '请选择分类', thumbnail: '缩略图',
            },
            // 修改信息
            edit:
            {
                title: '编辑材质',
            },
            delete:
            {
                confirmDel: '是否删除当前材质?',
            },
        },
        vfx: {
            midPanel:
            {
                title: '特效库',
                importRes: '导入特效',
                emptyText: '请先通过{导入特效}新建特效资产',
                searchPlacehold: '请输入特效名称',
                searchNoRes: "搜索无结果",
            },
            add:
            {
                name: '特效名称', type: '特效分类', tag: '特效标签', namePlacehold: '请输入特效名称', typePlacehold: '请选择分类', thumbnail: '缩略图',
            },
            // 修改信息
            edit:
            {
                title: '编辑特效',
            },
            delete:
            {
                confirmDel: '是否删除当前特效?',
            },
        },
        point: {
            midPanel:
            {
                title: '点位库',
                importRes: '导入点位',
                emptyText: '请先通过{导入点位}新建点位资产',
                searchPlacehold: '请输入点位名称',
                searchNoRes: "搜索无结果",
            },
            add:
            {
                name: '点位名称', type: '点位分类', tag: '点位标签', namePlacehold: '请输入点位名称', typePlacehold: '请选择分类', thumbnail: '缩略图',
            },
            // 修改信息
            edit:
            {
                title: '编辑点位',
            },
            delete:
            {
                confirmDel: '是否删除当前点位?',
            },
        },
        typeManager:
        {
            add: '新建分类', searchPlacehold: '请输入分类名称',
            editType: '编辑分类', deleteType: '删除分类', sortMessage: '请输入正整数',
            typeName: '分类名称', typeID: '分类ID', typeParent: '上级分类', sort: '排序', sortTips: '支持输入从1开始的正整数，按从小到大排序，未填写默认排在最后。',
            confirmDelType: '是否删除当前分类？', confirmDelTypeGroup: '该分类下有下级分类，删除后将归为未分类下，请谨慎操作',
        },
        action:
        {
            official: '官方',
            hover: '查看',
        },

    },
    message: {
        editSuccess: '编辑成功', editFail: '编辑失败', addSuccess: '新建成功', addFail: '新建失败', deleteSuccess: '删除成功', deleteFail: '删除失败',
        errorName: '请输入名称', errorType: '请选择分类', errorFile: '请导入文件',
        copySuccess: '复制成功', copyFail: '复制失败', errorCoordinateSystem: '请选择坐标系', coordinateSystemTips: '请上传坐标系prj文件',
        errorJoint: '请选择场景拼接', errorTag: '请选择场景标签', checkNameError: '名称不支持特殊字符', errorPermission: '请选择场景权限',
        user: {
            userName: '请输入用户名称', userRole: '请输入用户角色', userEmail: '请输入登录邮箱',
        },
        checkUserEmailError: '邮箱格式不对', checkUserPhoneError: '手机号码不对', checkUserNameError: '用户名仅支持中文、大小写字母、数字，且不超过20字符', checkUserRoleError: '用户角色不对',
        fileUploadFail: '文件上传失败'
    },
    permission: {
        SceneLayout: '场景编排', SceneReview: '场景审核', SceneConcat: '场景拼接', Preview: '预览', Edit: '编辑', Delete: '删除',
        Copy: '复制', SubmitReview: '提交审核',
    }

}
export default zh_CN;