<template>
  <l-side-nav
    :layout="settingStore.layout"
    :is-fixed="settingStore.isSidebarFixed"
    :menu="sideMenu"
    :is-compact="settingStore.isSidebarCompact"
  />
</template>

<script setup>
import { watch, ref } from "vue";

import { dashboardRouter } from "@/router";
import { pageStatusStore } from "@/store";

import LSideNav from "./SideNav.vue";

const pageStatus = pageStatusStore();
const settingStore = {
  isSidebarCompact: false,
  layout: "side",
  isSidebarFixed: true,
};
const sideMenu = ref([]);
watch(
  () => pageStatus.lang,
  () => {
    initMenu();
  }
);
const initMenu = () => {
  const tempMenu = [];
  dashboardRouter.children.forEach((item) => {
    if (item.meta) {
      const menuItem = {
        path: dashboardRouter.path + "/" + item.path,
        title: pageStatus.lang.menu[item.meta.type],
        icon: item.meta.icon,
        id: item.meta.id,
        children: [],
      };

      if (item.meta.pid === undefined) {
        tempMenu.push(menuItem);
        console.info(menuItem);
      } else {
        const pMenuItem = tempMenu.find((i) => i.id === item.meta.pid);
        pMenuItem?.children.push(menuItem);
      }
    }
    sideMenu.value = tempMenu;
  });
};
initMenu();
</script>
