<template>
  <t-dialog
    v-model:visible="dialogVisible"
    :header="pageStatus.lang.lang.title"
    :width="540"
    :on-confirm="handleConfirm"
  >
    <template #body>
      <t-form :rules="rules" label-align="top">
        <t-form-item
          :label="pageStatus.lang.lang.selectLang"
          name="lang"
          :data="langFormData"
        >
          <t-select v-model="langFormData.lang" :options="langTypes" />
        </t-form-item>
      </t-form>
    </template>
  </t-dialog>
</template>
<script>
export default {
  name: "language",
};
</script>
<script setup>
import { watch, ref } from "vue";
import { MessagePlugin } from "tdesign-vue-next";

import { pageStatusStore } from "@/store";
import { langType } from "@/config/lang/index";
import { updateProjectUserSetting } from "@/api/project";

const pageStatus = pageStatusStore();

const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  language: {
    type: String,
    default: "",
  },
  userInfo: {
    type: Object,
    default: () => {
      return {};
    },
  },
});
const emit = defineEmits(["update:visible", "updatelang"]);

const dialogVisible = ref(props.visible);

// langTypes内容不需要跟随语言翻译转换
const langTypes = [
  { label: "简体中文", value: langType.zh_CN },
  { label: "English（英文）", value: langType.en },
];
const langFormData = ref({
  lang: props.language,
});
const rules = {
  lang: [{ required: true, message: "error", type: "error" }],
};

watch(
  () => props.visible,
  (val) => {
    dialogVisible.value = val;
  }
);
watch(
  () => dialogVisible.value,
  (val) => {
    emit("update:visible", val);
  }
);
watch(
  () => props.language,
  (val) => {
    langFormData.value.lang = val;
  }
);

const handleConfirm = () => {
  updateProjectUserSetting({
    email: props.userInfo.email,
    language: langFormData.value.lang,
    project_id: pageStatus.project.value,
  }).then((result) => {
    if (result.code == 200) {
      dialogVisible.value = false;
      emit("updatelang", langFormData.value.lang);
      MessagePlugin.success(pageStatus.lang.message.editSuccess);
    } else {
      MessagePlugin.error(pageStatus.lang.message.editFail);
    }
  });
};
</script>

<style lang="less" scope>
</style>
