import { request } from "../utils/request"
import { setStorage, deleteStorage, getStorage } from "@/utils/localStorage"
import { server } from "@/config/global"

// TODO：
// const server = "https://metaeditor.tdt.qq.com/";
// const server = "http://9.134.218.162:8000/api/v1/auth/";
export const _TOKEN = 'token'
const L = server + 'api/v1/auth/'
export const REQUEST_SUCCESS = 'success';

/**
 * @param _RememberObj
 * @return Rembered
 * isRemember() 是否记住密码
 * onSaveAccout() 设置缓存账号
 * onDeleteAccout() 删除缓存账号
 */
export const _RememberObj = '_AccoutInfo'

export const login = (data) => {
  return request({
    url: L + "login",
    method: "post",
    data: {
      ...data
    },
  }).then((res) => {
    if (res.data) {
      setStorage(_TOKEN, res.data.token)
      return REQUEST_SUCCESS
    } else {
      return res
    }
  }).catch(err => {
    return '系统错误！'
  })
}

// 退出登录
export const logout = () => {
  return request({
    url: L + "logout",
    method: 'post',
    data: {
      token: getStorage(_TOKEN)
    }
  })
}

export const getCodeImg = () => {
  return request({
    url: `${L}captcha`,
    method: "get",
  }).then((res) => {
    return res
  })
}

export const setPassword = (data) => {
  return request({
    url: `${L}reset/password`,
    method: "post",
    data,
  }).then((res) => {
    console.log(res);
    if (res?.code === 200) {
      return REQUEST_SUCCESS
    }
    return res
  })
}

export const getEmailCode = (data) => {
  return request({
    url: `${L}email/captcha`,
    method: "get",
    data
  }).then((res) => {
    console.log('email', res);
  })
}

export const onSaveAccout = (data) => {
  setStorage(_RememberObj, { ...data })
}

export const onDeleteAccout = () => {
  deleteStorage(_RememberObj)
}

export const isRemember = () => {
  const accoutObj = getStorage(_RememberObj)
  return !!accoutObj
}