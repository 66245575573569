import { addToken } from "./localStorage";
import {
  DEFAULT_PIC,
  DEFAULT_SCENE_PIC,
  DEFAULT_RESOURCE_PIC,
} from "@/config/global";

// 防抖函数
export const debounce = function (fn) {
  let timeId = null;
  // 这里不能是箭头函数，否则下面透传参数arguments获取的是fn，因为箭头函数不支持arguments
  return function () {
    if (timeId) {
      clearTimeout(timeId);
    }
    // 获取透传参数
    let args = Array.prototype.slice.call(arguments);
    timeId = setTimeout(() => {
      clearTimeout(timeId);
      timeId = null;
      fn(...args);
    }, 200);
  };
};

// 拼接字符，避免浏览器版本差异导致显示不符合预期
export const formatDateString = function (timeStr, needTime = false) {
  const d = timeStr ? new Date(timeStr) : new Date();
  let month = d.getMonth() + 1;
  const date = `${d.getFullYear()}-${getFormatNum(month)}-${getFormatNum(d.getDate())}`;
  const time = `${getFormatNum(d.getHours())}:${getFormatNum(d.getMinutes())}:${getFormatNum(d.getSeconds())}`;
  if (needTime) return [date, time].join(" ");
  return date;
}
const getFormatNum = function (num) {
  return num < 10 ? `0${num}` : num;
}

// 校验是否包含特殊字符
export const checkSpecialChars = function (string) {
  const regEn = /[`~!@#$%^&*()_+=<>?:"{},.\/;'[\]]/im,
    regCn = /[-·！～#¥…（——）：；“”‘、，｜《。》？、【】[\]]/im;
  if (regEn.test(string) || regCn.test(string)) {
    return true
  } else {
    return false
  }
}
// 对名称、tag等输入信息校验，和服务端保持一致：允许特殊字符串&()（）—=\\-【】、
export const checkNameStr = function (string) {
  const reg = /^[\u4e00-\u9fa5a-zA-Z0-9()（）&—=\-【】]{1,20}$/;
  if (string.search(reg) !== -1) {
    return true
  } else {
    return false
  }
}

export const copyToClipboard = (str, success, fail) => {
  console.info("copy");
  // if (navigator.clipboard) {
  //   navigator.clipboard
  //     .writeText(str)
  //     .then(() => success && success())
  //     .catch(() => { fail && fail() });
  // } else {
  try {
    console.info("当前浏览器不支持clipboard");
    const dom = document.createElement("input");
    dom.value = str;
    document.body.appendChild(dom);
    dom.select();
    document.execCommand("copy");
    document.body.removeChild(dom);
    success && success();
  } catch (error) {
    fail && fail();
  }
  // }
};

export const getUrlParam = function (url) {
  if (url == null || url == "") {
    url = window.location.href;
  }
  url = decodeURI(url);
  const paramMap = {};
  const firstIndex = url.indexOf("?");
  let paramStr = url.substring(firstIndex + 1);
  if (paramStr && paramStr != "") {
    const index = paramStr.indexOf("?") //再次检查参数，避免后面有参数的值是访问地址
    let lastVal = "";
    const sflag = false; //标识有第二个问号
    if (index > 0) {
      const urlTemp = paramStr.substring(0, index);
      const lastIndex = urlTemp.lastIndexOf("=");
      lastVal = paramStr.substring(lastIndex + 1);
      paramStr = paramStr.substring(0, lastIndex + 1);
    }
    const paramStrArr = paramStr.split("&");
    for (var i = 0; i < paramStrArr.length; i++) {
      const temp = paramStrArr[i];
      const indexE = temp.indexOf("=");
      const pKey = temp.substring(0, indexE);
      let pVal = "";
      if (sflag && i == (paramStrArr.length - 1)) {
        pVal = lastVal;
      } else {
        pVal = temp.substring(indexE + 1);
      }
      paramMap[pKey] = pVal;
    }
  }
  return paramMap;
}
// 密码校验
export const regPassword = /^\S*(?=\S{8,16})(?=\S*\d)(?=\S*[A-Z])(?=\S*[a-z])\S*$/

// 登录图片验证码
export const regCode = /^[0-9]{6}$/

// 邮箱
export const regEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

// 缩略图url获取逻辑
export const getImgSrc = (item, isScene) => {
  let url = "";
  if (item && item.thumbnail_url && item.thumbnail_url !== DEFAULT_PIC) {
    // 正常上传了缩略图的情况
    // 其中：item.thumbnail_url !== DEFAULT_PIC是为了兼容之前资产用户没传缩略图时前端默认传了DEFAULT_PIC给服务端的逻辑
    url = addToken(item.thumbnail_url);
  } else if (isScene) {
    // 没上传且是场景列表时
    url = DEFAULT_SCENE_PIC;
  } else {
    // 没上传且是资产列表时
    url = DEFAULT_RESOURCE_PIC;
  }
  return url;
};


