<template>
  <t-dialog v-model:visible="dialogVisible" :width="800" :footer="false">
    <template #header>
      <div class="title">{{ pageStatus.lang.projectInfo.title }}</div>
    </template>
    <template #body>
      <div class="second-title">{{ pageStatus.lang.projectInfo.baseInfo }}</div>
      <t-form class="user-detail" labelAlign="left">
        <t-form-item
          :label="pageStatus.lang.projectInfo.projectName"
          name="projectName"
        >
          {{ pageStatus.project.label }}
        </t-form-item>
        <t-form-item
          :label="pageStatus.lang.projectInfo.projectID"
          name="projectID"
        >
          {{ pageStatus.project.value }}
          <copy class="icon-copy" :str="pageStatus.project.value" />
        </t-form-item>
        <t-form-item :label="pageStatus.lang.projectInfo.token" name="token">
          <input
            class="token-input"
            :type="showToken ? 'text' : 'password'"
            disabled
            :value="pageStatus.project.token"
          />
          <copy class="icon-copy" :str="pageStatus.project.token" />

          <icon
            :name="showToken ? `${ICON_TYPE.view_on}` : `${ICON_TYPE.view_off}`"
            :url="iconUrl"
            @click="handleShowClick"
          />
        </t-form-item>
        <t-form-item
          :label="pageStatus.lang.projectInfo.validateDate"
          name="validateDate"
        >
          {{
            pageStatus.project.validPeriod === -1
              ? "永久"
              : formatDateString(pageStatus.project.expiredAt, true)
          }}
        </t-form-item>
      </t-form>
    </template>
  </t-dialog>
</template>

<script>
export default {
  name: "DashboardProjectInfo",
};
</script>

<script setup>
import { ref, watch } from "vue";
import { Icon } from "tdesign-icons-vue-next";

import { pageStatusStore } from "@/store";
import { ICON_TYPE } from "@/utils/buttonStyle";
import { formatDateString } from "@/utils/common";

import Copy from "@/components/Copy.vue";

const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
});

const iconUrl = "/icon/icon.js";
const emit = defineEmits(["update:visible"]);

const pageStatus = pageStatusStore();

const dialogVisible = ref(false);
const showToken = ref(false);

watch(
  () => props.visible,
  (val) => {
    dialogVisible.value = val;
  }
);
watch(
  () => dialogVisible.value,
  (val) => {
    emit("update:visible", val);
    if (!val) {
      // 重置
      showToken.value = false;
    }
  }
);

const handleShowClick = () => {
  showToken.value = !showToken.value;
};
</script>

<style lang="less" scoped>
.title {
  font-size: 18px;
  line-height: 18px;
}
.second-title {
  font-size: 16px;
  margin: 20px 0px;
}

.user-detail {
  background: rgba(255, 255, 255, 0.04);
  border: 0px;
  border-radius: 10px;
  padding: 20px;
  :deep(.t-form__label) {
    color: var(--white-white-60, rgba(255, 255, 255, 0.6));
    /* 普通字体_PingFang/小字号04_14/regular */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
  :deep(.t-form__item) {
    margin-bottom: var(--td-comp-margin-m);
  }
  .token-input {
    background: rgba(0, 0, 0, 0);
    border: none;
    color: white;
    width: 200px;
  }
  .icon-copy {
    height: 18px;
  }
}
</style>