// 封装localStorage

const isSupportLS = typeof (Storage) && localStorage.setItem && localStorage.getItem && localStorage.removeItem && localStorage.clear;

const local = {
  set(key, value) {
    if (!key || !value) {
      return null
    }
    key = key.toString();
    window.localStorage.setItem(key, JSON.stringify(value));
  },
  get(key) {
    if (!key) {
      return null
    }
    key = key.toString();
    const data = window.localStorage.getItem(key);
    return JSON.parse(data);
  },
  support: () => isSupportLS,
  deleteKey(key) {
    if (!key) {
      return;
    }
    key = key.toString();
    window.localStorage.removeItem(key);
  },
  clear() {
    window.localStorage.clear();
  }
};

// 由于html标签无法设置请求头，这里给返回的url添加token(临时方案)
export function addToken(url) {
  let joinStr = '?'
  if (url&&url.indexOf('?') > -1) joinStr = '&'
  return `${url}${joinStr}token=${getStorage('token')}`
}

export function getStorage(cacheId) {
  if (local.support()) {
    let cache = local.get(cacheId)
    if (!cache) return null;
    return cache
  }
}

export function setStorage(cacheId, storage) {
  if (local.support()) {
    local.set(cacheId, storage);
  }
}

export function deleteStorage(key) {
  if (local.support()) {
    local.deleteKey(key)
  }

}
export default local;
