//首页

export const en = {
    // logo 默认logo是否需要支持多语言版本??
    // 头部 项目名称？？
    emptyProject: {
        description: 'description',
    },
    // 用户下拉
    userDropdown: [
        // 用户名称？？
        '个人中心',
        '权限管理',
        '设置',
        '语言lang',
        '退出登录',
    ],
    userDropdown: {
        // 用户名称？？
        personCenter: 'personCenter',
        authManager: 'authManager',
        projectInfo: 'projectInfo',
        setting: 'setting',
        lang: 'lang',
        logout: 'logout',
    },
    // 个人中心弹窗
    userCenter: [
        '个人信息',
        '用户信息', '用户名称', '登录邮箱',
        '用户角色', '项目名称', '用户角色',
    ],
    userCenter: {
        title: 'title',
        userInfo: 'userInfo', userName: 'userName', userEmail: 'userEmail',
        userRole: 'userRole', projectName: 'projectName', roleName: 'roleName',
    },
    // 权限管理
    authManager: [
        '权限管理',
        '用户管理',
        '新建用户', '筛选角色', '邮箱', '用户名', '请输入内容',
        '用户名称', '用户角色', '登录邮箱', '操作',
        '用户详情', '用户编辑', '是否删除当前用户',
        '角色管理',
        '角色名称', '创建时间', '角色描述', '操作',
        '权限详情',
    ],
    authManager: {
        title: 'title', editRole: 'editRole',
        userManager: 'userManager', addRole: 'addRole',
        addUser: 'addUser', filterRole: 'filterRole', filterUserEmail: 'filterUserEmail', filterUserName: 'filterUserName', searchContent: 'searchContent',
        userOverLimitTips: 'userOverLimitTips',
        namePlacehold: "namePlacehold", emailPlacehold: "emailPlacehold", rolePlacehold: "rolePlacehold",
        userName: 'userName', userRole: 'userRole', userEmail: 'userEmail', operation: 'operation',
        userDetail: 'userDetail', editUser: 'editUser', confirmDelUser: 'confirmDelUser?', confirmDelRole: 'confirmDelRole?', delRoleTips: 'delRoleTips',
        roleManager: 'roleManager', roleNamePlacehold: 'roleNamePlacehold', roleDescPlacehold: 'roleDescPlacehold',
        roleName: 'roleName', createTime: 'createTime', roleDesc: 'roleDesc', operation: 'operation',
        roleDetail: 'roleDetail', rolePermission: 'rolePermission', rolePermissionPlacehold: 'rolePermissionPlacehold'
    },
    // 设置
    setting: [
        '设置',
        '编辑',
        '项目配置', 'LOGO设置',
        '取消', '保存',
        '默认', '自定义上传',
        'LOGO推荐尺寸为248*32px，大小50KB以下，建议上传svg格式',
    ],
    // 设置
    setting: {
        title: 'title',
        edit: 'edit', permissionTips: 'permissionTips', logoConfigTips: 'logoConfigTips',
        projectConfig: 'projectConfig', logoConfig: 'logoConfig',
        cancel: 'cancel', save: 'save',
        default: 'default', custom: 'custom',
        customTips: 'customTips',
    },
    // 语言
    lang: [
        '语言设置',
        '请选择系统语言',
    ],
    // 语言
    lang: {
        title: 'title',
        selectLang: 'selectLang',
    },
    // 项目信息弹窗
    projectInfo: {
        title: 'title',
        baseInfo: 'baseInfo', projectName: 'projectName', projectID: 'projectID', token: 'token', validateDate: 'validateDate',
        projectSetting: 'projectSetting', settingName: 'settingName', settingValue: 'settingValue',
    },
    // 退出登录
    logout: [
        '是否退出当前用户',
    ],
    logout: {
        confirmLogout: 'confirmLogout',
    },
    // 左侧菜单
    menu: [
        '项目管理', '项目场景',
        '资产库', '模型库', 'NPC库', '材质库', '特效库'
    ],
    menu: {
        projectManager: 'projectManager', projectScenes: 'projectScenes',
        resource: 'resource', model: 'model', npc: 'npc', apparel: 'apparel', texture: 'texture', vfx: 'vfx', point: 'point'
    },
    // 右侧主面板
    dashboard: [
        /**
         * 
         * 项目场景
         * 
         */
        // middlebar
        [
            '项目场景', '个',//多少个场景
            '项目发布', '新建场景', '拼接场景', '提交审核',
            '按最新修改日期', '按创建时间', '按名称', '选择标签', '请输入场景名称', '标签管理', '发布管理',
        ],
        // 新建场景
        [
            '基础底板',
            '场景名称', '场景拼接', '场景标签', '请下拉选择或输入新标签后回车添加', '取消', '确定',
            '导入场景',
            '导入文件', '客户端', '服务端', '还未上传文件', '支持上传一个UE4.27 pak文件',
        ],
        // 标签管理
        [
            '新建标签', '请输入标签名称',
            '标签名称', '场景数', 'NPC数', '模型数', '材质数', '特效数', '操作', '确定',
            '编辑标签',
            '请按行输入标签，20字以内，不支持特殊字符，单次最多允许上传100条标签',
            '是否删除当前标签?',
        ],
        // 发布管理
        [
            '项目发布管理',
            '正式环境', '上次发布时间', '复制链接', '发布中',
        ],
        // middlepanel
        [
            '更新时间', '主场景', '分场景',
            '预览', '编辑',
            '复制场景', '查看信息', '修改信息', '删除',
        ],
        // 复制场景
        [
            '确认复制', '是否复制当前场景？',
        ],
        // 查看信息
        [
        ],
        // 修改信息
        [
            '编辑场景',
        ],
        // 删除
        [
            '确认删除', '是否删除当前场景？',
        ],
        /**
         * 
         * 模型库，部分和场景相同的未单列，NPC、材质、特效和模型差别就是将模型替换为对应的名称（NPC、材质、特效）
         * 
         */
        // middlebar
        [
            '模型库', '个',//多少个模型
            '导入模型',
            '选择分类', '请输入模型名称', '分类管理',
        ],
        // 导入模型
        [
            '模型名称', '模型分类', '模型标签', '请输入模型名称', '请选择分类', '缩略图',
            '导入文件', '客户端', '服务端', '还未上传文件', '支持上传UE pak4.27文件',
        ],
        // 分类管理
        [
            '新建分类', '请输入分类名称',
            '分类名称', '分类ID', '上级分类',
        ],
        // middlepanel
        [
            '官方',
            '查看',
            '修改信息', '删除',
        ],
        // 修改信息
        [
            '编辑模型',
        ],
        // 删除
        [
            '是否删除当前模型?',
        ],
    ],
    dashboard: {
        /**
         * 
         * 项目场景
         * 
         */
        middleBar:
        {
            items: 'items',//多少个场景
            byLastUpdateTime: 'byLastUpdateTime', byCreateTime: 'byCreateTime', byName: 'byName',
            selectOrderBy: 'selectOrderBy', selectTag: 'selectTag', selectType: 'selectType',
            tagManager: 'tagManager', publishManager: 'publishManager', typeManager: 'typeManager',
            pubProcessTip: 'pubProcessTip', tipConfirm: 'tipConfirm',
            tipContentPart1: 'tipContentPart1', tipContentPart2: 'tipContentPart2', tipContentPart3: 'tipContentPart3',

        },
        tagManager:
        {
            add: 'addTag', searchPlacehold: 'searchPlacehold',
            tagName: 'tagName', sceneCount: 'sceneCount', npcCount: 'npcCount', apparelCount: 'apparelCount', modelCount: 'modelCount', textureCount: 'textureCount', vfxCount: 'vfxCount', pointCount: 'pointCount', operation: 'operation',
            editTag: 'editTag',
            addTips: 'addTips',
            confirmDelTag: 'confirmDelTag?',
        },
        // 发布管理
        publishManager:
        {
            title: 'title',
            prodEnv: 'prodEnv', lastPublishTime: 'lastPublishTime', copyLink: 'copyLink', publishing: 'publishing',
            emptyResourceTip: 'emptyResourceTip',
        },
        projectScenes: {
            midPanel:
            {
                title: 'title', searchPlacehold: 'searchPlacehold',
                publishProject: 'publishProject', addScene: 'addScene', joinScene: 'joinScene', commit: 'commit',
                withoutEnvTips: 'withoutEnvTips', sceneOverLimitTips: 'sceneOverLimitTips',
                updateTime: 'updateTime', mainScene: 'mainScene', otherScene: 'otherScene',
                preview: 'preview', edit: 'edit',
                copyScene: 'copyScene', sceneInfo: 'sceneInfo', editScene: 'editScene', delete: 'delete',
                emptyText: "emptyText",
                emptyTitle: "emptyTitle",
                searchNoRes: "searchNoRes",
            },
            add:
            {
                baseTemplate: 'baseTemplate', scenePermission: 'scenePermission',
                sceneName: 'sceneName', sceneJoint: 'sceneJoint', sceneTag: 'sceneTag', tagPlacehold: 'tagPlacehold', cancel: 'cancel', confirm: 'confirm',
                importScene: 'importScene', reupload: 'reupload', delete: 'delete', coordinateTypeTip: 'coordinateTypeTip', coordinateFileTip: 'coordinateFileTip',
                coordinateSystemSelect: 'coordinateSystemSelect', worldCoordinateSystem: 'worldCoordinateSystem', geographicalCoordinateSystem: 'geographicalCoordinateSystem',
                importFile: 'importFile', client: 'client', server: 'server', importPlacehold: 'importPlacehold', importTips: 'importTips',
                confirmDelFile: 'confirmDelFile', deleteFile: 'deleteFile', fileFailTip: 'fileFailTip', fileEmptyTip: 'fileEmptyTip',
                tagEmpty: 'tagEmpty', tagLengthTip: 'tagLengthTip', tagExistTip: 'tagExistTip', offsetValue: 'offsetValue', roleTips: 'roleTips',
                offsetTip: 'offsetTip', offsetPlaceholdX: 'offsetPlaceholdX', offsetPlaceholdZ: 'offsetPlaceholdZ', offsetPlaceholdY: 'offsetPlaceholdY',
            },
            copy:
            {
                confirmCopy: 'confirmCopy', confirmCopyScene: 'confirmCopyScene', checkRoles: 'checkRoles', copyTips: 'copyTips'
            },
            // 查看信息

            // 修改信息
            edit:
            {
                editScene: 'editScene',
            },
            // 删除
            delete:
            {
                confirmDel: 'confirmDel', confirmDelScene: 'confirmDelScene',
            },
        },
        /**
         * 
         * 模型库，部分和场景相同的未单列，NPC、材质、特效和模型差别就是将模型替换为对应的名称（模型,模型、NPC、材质、特效）
         * 
         */
        model: {
            midPanel:
            {
                title: 'title',
                importRes: 'importRes',
                emptyText: 'empty{IMPORT model}Text',
                searchPlacehold: 'searchPlacehold',
                searchNoRes: "searchNoRes",
            },
            add:
            {
                name: 'name', type: 'type', tag: 'tag', namePlacehold: 'namePlacehold', typePlacehold: 'typePlacehold', thumbnail: 'thumbnail',
            },
            // 修改信息
            edit:
            {
                title: 'title',
            },
            delete:
            {
                confirmDel: 'confirmDel',
            },
        },
        npc: {
            midPanel:
            {
                title: 'title',
                importRes: 'importRes',
                emptyText: 'empty{IMPORT npc}Text',
                searchPlacehold: 'searchPlacehold',
                searchNoRes: "searchNoRes",
            },
            add:
            {
                name: 'name', type: 'type', tag: 'tag', namePlacehold: 'namePlacehold', typePlacehold: 'typePlacehold', thumbnail: 'thumbnail', properties: 'properties', propertiesPlacehold: 'propertiesPlacehold',
                propertyPlayer: 'propertyPlayer', propertyNPC: 'propertyNPC',
            },
            // 修改信息
            edit:
            {
                title: 'title',
            },
            delete:
            {
                confirmDel: 'confirmDel',
            },
        },
        apparel: {
            midPanel:
            {
                title: 'title',
                importRes: 'importRes',
                emptyText: 'empty{IMPORT npc}Text',
                searchPlacehold: 'searchPlacehold',
                searchNoRes: "searchNoRes",
            },
            add:
            {
                name: 'name', type: 'type', tag: 'tag', namePlacehold: 'namePlacehold', typePlacehold: 'typePlacehold', thumbnail: 'thumbnail', properties: 'properties', propertiesPlacehold: 'propertiesPlacehold',
                propertyPlayer: 'propertyPlayer', propertyNPC: 'propertyNPC',
            },
            // 修改信息
            edit:
            {
                title: 'title',
            },
            delete:
            {
                confirmDel: 'confirmDel',
            },
        },
        texture: {
            midPanel:
            {
                title: 'title',
                importRes: 'importRes',
                emptyText: 'empty{IMPORT texture}Text',
                searchPlacehold: 'searchPlacehold',
                searchNoRes: "searchNoRes",
            },
            add:
            {
                name: 'name', type: 'type', tag: 'tag', namePlacehold: 'namePlacehold', typePlacehold: 'typePlacehold', thumbnail: 'thumbnail',
            },
            // 修改信息
            edit:
            {
                title: 'title',
            },
            delete:
            {
                confirmDel: 'confirmDel',
            },
        },
        vfx: {
            midPanel:
            {
                title: 'title',
                importRes: 'importRes',
                emptyText: 'empty{IMPORT vfx}Text',
                searchPlacehold: 'searchPlacehold',
                searchNoRes: "searchNoRes",
            },
            add:
            {
                name: 'name', type: 'type', tag: 'tag', namePlacehold: 'namePlacehold', typePlacehold: 'typePlacehold', thumbnail: 'thumbnail',
            },
            // 修改信息
            edit:
            {
                title: 'title',
            },
            delete:
            {
                confirmDel: 'confirmDel',
            },
        },
        point: {
            midPanel:
            {
                title: 'title',
                importRes: 'importRes',
                emptyText: 'empty{IMPORT}Text',
                searchPlacehold: 'searchPlacehold',
                searchNoRes: "searchNoRes",
            },
            add:
            {
                name: 'name', type: 'type', tag: 'tag', namePlacehold: 'namePlacehold', typePlacehold: 'typePlacehold', thumbnail: 'thumbnail',
            },
            // 修改信息
            edit:
            {
                title: 'title',
            },
            delete:
            {
                confirmDel: 'confirmDel',
            },
        },
        typeManager:
        {
            add: 'addType', searchPlacehold: 'addPlacehold',
            editType: 'editType', deleteType: 'deleteType', sort: 'sort', sortTips: 'sortTips', sortMessage: 'sortMessage',
            typeName: 'typeName', typeID: 'typeID', typeParent: 'typeParent',
            confirmDelType: 'confirmDelType', confirmDelTypeGroup: 'confirmDelTypeGroup',
        },
        action:
        {
            official: 'official',
            hover: 'hover',
        },

    },
    message: {
        editSuccess: 'editSuccess', editFail: 'editFail', addSuccess: 'addSuccess', addFail: 'addFail', deleteSuccess: 'deleteSuccess', deleteFail: 'deleteFail',
        errorName: 'errorName', errorType: 'errorType', errorFile: 'errorFile',
        copySuccess: 'copySuccess', copyFail: 'copyFail', errorCoordinateSystem: 'errorCoordinateSystem', coordinateSystemTips: 'coordinateSystemTips',
        coordinateSystem: 'coordinateSystem',
        errorJoint: 'errorJoint', errorTag: 'errorTag', checkNameError: 'checkNameError',
        user: {
            userName: 'userName', userRole: 'userRole', userEmail: 'userEmail',
        },
        checkUserEmailError: 'checkUserEmailError', checkUserPhoneError: 'checkUserPhoneError', checkUserNameError: 'checkUserNameError', checkUserRoleError: 'checkUserRoleError',
        fileUploadFail: 'fileUploadFail'
    },
    permission: {
        SceneLayout: 'SceneLayout', SceneReview: 'SceneReview', SceneConcat: 'SceneConcat', Preview: '预览', Edit: 'Edit', Delete: 'Delete',
        Copy: 'Copy', SubmitReview: 'SubmitReview',
    }
}
export default en;