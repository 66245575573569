if (window.ue && window.ue.interface) {
    console.info('now is UE');
    /**
     * ue往web发消息
     * @param {*} data 消息
     */
    window.ue.interface.uetoweb = function (data) {
        console.info(data);
    };
    /**
     * web往ue发消息
     * @param {*} params json结构的字符串参数
     * @param {*} callback 回调，不需要的时候可不传
     */
    window.webtoue = function (params, callback) {
        var name = 'sendInChannel';
        // var b = '{}';//json 结构
        // var c = 'uetoweb';//回调函数名称
        window.ue.interface.broadcast(name, params, callback);
    };
}