import { createRouter, createWebHistory } from 'vue-router';
import { DashboardIcon, } from 'tdesign-icons-vue-next';
import { ref } from 'vue';
import Layout from '@/layouts/index.vue';
import { getStorage } from '@/utils/localStorage';
import { ICON_TYPE } from "@/utils/buttonStyle";
import { MENU_TYPES } from '@/config/global';
import { _TOKEN } from '@/api/login'

export const dashboardRouter = {
  path: '/dashboard',
  component: Layout,
  redirect: '/dashboard/scene/manager',
  name: 'dashboard',
  meta: {
    title: '',
    icon: DashboardIcon,
    orderNo: 0,
  },
  children: [
    {
      path: 'scene',
      name: 'DashboardScene',
      component: () => import('@/pages/dashboard/scene/index.vue'),
      meta: {
        // title: '项目管理',
        type: MENU_TYPES.projectManager,
        icon: ICON_TYPE.task,
        id: '1',
      },
    },
    {
      path: 'scene/manager',
      name: 'DashboardSceneManager',
      component: () => import('@/pages/dashboard/scene/index.vue'),
      meta: {
        // title: '项目场景',
        type: MENU_TYPES.projectScenes,
        icon: ICON_TYPE.task,
        pid: '1',
        id: '1-1',
      },
    },
    // {
    //   path: 'scene/approve',
    //   name: 'DashboardSceneApprove',
    //   component: () => import('@/pages/dashboard/scene/index.vue'),
    //   meta: {
    //     title: '分场景审批',
    //     icon: AppIcon,
    //     pid:'1',
    //     id:'1-2',
    //   },
    // },
    {
      path: 'resource',
      name: 'DashboardResource',
      redirect: '/dashboard/resource/npc',
      meta: {
        // title: '资产库',
        type: MENU_TYPES.resource,
        icon: ICON_TYPE.npc,
        id: '2',
      },
    },
    {
      path: 'resource/model',
      name: 'DashboardResourceModel',
      component: () => import('@/pages/dashboard/resource/index.vue'),
      meta: {
        type: MENU_TYPES.model,
        icon: ICON_TYPE.model,
        pid: '2',
        id: '2-1',
      },
    },
    {
      path: 'resource/npc',
      name: 'DashboardResourceNPC',
      component: () => import('@/pages/dashboard/resource/index.vue'),
      meta: {
        type: MENU_TYPES.npc,
        icon: ICON_TYPE.npc,
        pid: '2',
        id: '2-2',
      },
    },
    {
      path: 'resource/apparel',
      name: 'DashboardResourceApparel',
      component: () => import('@/pages/dashboard/resource/index.vue'),
      meta: {
        type: MENU_TYPES.apparel,
        icon: ICON_TYPE.apparel,
        pid: '2',
        id: '2-3',
      },
    },
    {
      path: 'resource/texture',
      name: 'DashboardResourceTexture',
      component: () => import('@/pages/dashboard/resource/index.vue'),
      meta: {
        type: MENU_TYPES.texture,
        icon: ICON_TYPE.texture,
        pid: '2',
        id: '2-4',
      },
    },
    {
      path: 'resource/vfx',
      name: 'DashboardResourceVfx',
      component: () => import('@/pages/dashboard/resource/index.vue'),
      meta: {
        type: MENU_TYPES.vfx,
        icon: ICON_TYPE.vfx,
        pid: '2',
        id: '2-5',
      },
    },
    {
      path: 'resource/point',
      name: 'DashboardResourcePoint',
      component: () => import('@/pages/dashboard/resource/index.vue'),
      meta: {
        type: MENU_TYPES.point,
        icon: ICON_TYPE.point,
        pid: '2',
        id: '2-6',
      },
    },
    {
      path: 'setting',
      name: 'DashboardSetting',
      component: () => import('@/pages/dashboard/setting/index.vue'),
    },
    // 用户、角色管理
    {
      path: 'user/manager',
      name: 'DashboardUserManager',
      component: () => import('@/pages/dashboard/user/UserManager.vue'),
    },
    {
      path: ':source(.*)*/tagManager',
      name: 'DashboardTagManager',
      component: () => import('@/pages/dashboard/components/manager/TagManager.vue'),
    },
    {
      path: ':source(.*)*/typeManager',
      name: 'DashboardTypeManager',
      component: () => import('@/pages/dashboard/components/manager/TypeManager.vue'),
    },
  ],
}
const defaultRouterList = [
  {
    path: '/',
    redirect: '/dashboard',
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/pages/dashboard/login/index.vue')
  },
  dashboardRouter,
];


export const allRoutes = [...defaultRouterList];

const router = createRouter({
  history: createWebHistory('/'),
  routes: allRoutes,
});
export const dashboardTabRoute = ref('');
router.afterEach((to) => {
  if (to.path.startsWith('/dashboard/')) {
    dashboardTabRoute.value = to.path;
  }
})

// 页面失效前最后一次的路由地址
// export const _lastPath = 'lastVisitedPath'

router.beforeEach((to, from, next) => {

  if (!getStorage(_TOKEN) && to.name !== 'login') {
    next({ name: 'login' })
  } else if (getStorage(_TOKEN) && to.name === 'login') {
    return next({ name: from.name })
  } {
    next()
  }
  // 登录历史功能
  // if(!!getStorage(_TOKEN) && to.name === 'login'){
  //   return next('/')
  // } else if(!getStorage(_TOKEN) && !['login'].includes(to.name)) {
  //   // 如果用户未登录且不是访问登录页，则报错当前了具有并跳转到登录
  //   return next({name:'login'})
  // }else {
  //   setStorage(_lastPath,from.fullPath)
  // }
  // next()
})
export default router;
