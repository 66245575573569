
//兼容UE插件里不支持元素自带的scroll函数，可能跟原生的并不完全一致，只是先保障了标签页里自动滑动的效果
if(HTMLElement.prototype.scroll === undefined){
    HTMLElement.prototype.scroll = function ({ left, top, behavior }) {
        if (behavior === 'smooth') {
          let n = 0;
          let delta = 0;
          if (left !== undefined) {
            delta = left - this.scrollLeft;
          }
          if (top !== undefined) {
            delta = top - this.scrollTop;
          }
          const intervalId = setInterval(() => {
            n++;
            if (left !== undefined) {
              this.scrollLeft += delta / 10;
            }
            if (top !== undefined) {
              this.scrollTop += delta / 10;
            }
      
            if (n >= 10) {
              clearInterval(intervalId);
            }
          }, 30)
        }else{
          if (left !== undefined) {
            this.scrollLeft = left;
          }
          if (top !== undefined) {
            this.scrollTop = top;
          }
        }
      }
}