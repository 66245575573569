import { createApp } from 'vue';

import 'reset-css'
import TDesign from 'tdesign-vue-next';
import 'tdesign-vue-next/es/style/index.css';
import { store } from './store';
import router from './router';
import '@/style/index.less';
import App from './App.vue';
import "amfe-flexible"
import '@/utils/UEMessage'
import '@/utils/polyfills'
import { setupDirectives } from '@/directives'
import { setupGlobComponents } from '@/components'
const app = createApp(App);

app.use(TDesign);
app.use(store);
app.use(router);
setupDirectives(app)
setupGlobComponents(app)
app.mount('#app');
/**
 * 将生成的样式嵌入头部
 */
const mode = 'dark';
function insertThemeStylesheet(theme, key, color) {
  const root = `:root[theme-mode='${theme}']`;

  const styleSheet = document.createElement('style');
  styleSheet.type = 'text/css';
  styleSheet.innerText = `${root}{
        ${key}: ${color};
    }`;

  document.head.appendChild(styleSheet);
}
document.documentElement.setAttribute('theme-mode', mode);
//整体tags默认背景
insertThemeStylesheet('dark', '--td-gray-color-11', 'rgba(41, 41, 41, 1)');
//整体页面容器背景
insertThemeStylesheet('dark', '--td-gray-color-13', 'rgba(28, 28, 30, 1)');
//选择器背景
insertThemeStylesheet('dark', '--td-bg-color-specialcomponent', 'rgba(44, 44, 46, 1)');
//整体页面背景
insertThemeStylesheet('dark', '--td-gray-color-14', 'rgba(0, 0, 0, 1)');


