export const prefix = 'tdesign-starter';

// export const server = "https://9.135.88.192:8100/";
// export const server = "https://metaeditor.tencent.com/";
let domain = "https://metaeditor.tdt.qq.com/";
if (window.serverDomain) {
  domain = window.serverDomain;
  if (!window.serverDomain.endsWith('/')) {
    domain += '/';
  }
}
export const server = domain;

export const DEFAULT_PIC = '/default.png' // 历史使用的，当时资产和场景使用的同一个
export const DEFAULT_SCENE_PIC = '/default_scene.png' // 场景默认图片
export const DEFAULT_RESOURCE_PIC = '/default_resource.png' // 资产默认图片

export const JOINT_TYPES = [
  { labelKey: "mainScene", value: 1 },
  { labelKey: "otherScene", value: 2 },
]

export const FILE_TYPE_OPTS = [
  {
    label: "client",
    value: "windows_client",
    requiredMark: true,
  },
  {
    label: "server",
    value: "windows_server",
    requiredMark: false,
  },
]

export const getFileTypeOpts = (type, onlineMode) => {
  let fileTypeOpts = [];
  FILE_TYPE_OPTS.forEach(opt => {
    fileTypeOpts.push(Object.assign({}, opt))
  })
  switch (type) {
    case MENU_TYPES.projectScenes:
      fileTypeOpts[0].value = "base"; // 对应后台字段名
      fileTypeOpts[1].value = "server_base";
      fileTypeOpts[1].requiredMark = true;
      break;
    case MENU_TYPES.npc:
    case MENU_TYPES.vfx:
    case MENU_TYPES.apparel:
      fileTypeOpts[1].requiredMark = true;
      break;
  }
  if(onlineMode === ONLINE_MODE.single){
    // 单机模式不需要上传多人模式的资产
    fileTypeOpts.splice(1);
  }
  return fileTypeOpts
}

export const userDialogTypes = {
  detail: "detail",
  edit: "edit",
  add: "add",
  delete: "delete",
  copy: "copy",
};

export const userTypeArr = ["user", "role"];

export const MENU_TYPES = {
  projectManager: "projectManager",
  projectScenes: "projectScenes",
  resource: "resource",
  model: "model",
  npc: "npc",
  apparel: 'apparel',
  texture: "texture",
  vfx: "vfx",
  point: "point",
  screen: "screen"
}
// console.log(MENU_TYPES, "MENU_TYPES----global.js");

export const LOGO_URL = '/logo.svg';

export const BUY_URL = 'https://buy.cloud.tencent.com/Raydata_Verse?lang=zh';

export const ONLINE_MODE = {
  single: 'single',
  multi: 'multi',
}
export const PERMISSION_TYPES = {
  preview: 'Preview',
  edit: 'Edit',
  delete: 'Delete',
  copy: 'Copy',
  submitReview: 'SubmitReview',
  review: 'Review',
  concat: 'Concat'
}
