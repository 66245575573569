import { request } from "@/utils/request"
import { server } from "@/config/global";

const userRoot = server + 'api/v1/auth/user/';

// 获取用户列表
export const getUserList = (data) => {
    return request({
        url: userRoot + "list",
        method: "get",
        data
    })
}
// 新增用户
export const addUser = (data) => {
    return request({
        url: userRoot + "create",
        method: "post",
        data
    })
}
// 删除用户
export const deleteUser = (data) => {
    return request({
        url: userRoot + "" + data.user_id,
        method: "delete",
        config: {
            params: data
        }
    })
}
// 更新用户
export const updateUser = (data) => {
    return request({
        url: userRoot + "edit",
        method: "post",
        data
    })
}

// 获取用户个人信息
export const getUserInfo = (data) => {
    return request({
        url: userRoot + "info",
        method: "get",
        data
    })
}
