<template>
  <icon :name="ICON_TYPE[nameKey]" :url="iconUrl" />
</template>

<script setup>
import { Icon } from "tdesign-icons-vue-next";
import { ICON_TYPE } from "@/utils/buttonStyle";
const iconUrl = "/icon/icon.js";
defineProps({
  nameKey: {
    type: String,
    default: "",
  },
});
</script>

<style lang="less" scoped>
</style>