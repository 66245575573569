import zh_CN from "./zh_CN"
import en from "./en"
const getTestLang = (obj)=>{
    let keyObj = {};
    Object.keys(obj).forEach(key => {
        if(typeof obj[key] === 'object'){
            keyObj[key] = getTestLang(obj[key])
        }else{
            const template = obj[key].match(/\{(.+?)\}/);
            if(template){
                keyObj[key] = `${key}{${key}}${key}`
            }else{
                keyObj[key] = key;
            }
        }
    });
    return keyObj;
}

// 动态生成测试用的英文配置，避免手动添加
const enlang = getTestLang(zh_CN);
console.info(enlang)

export const lang = {
    zh_CN,
    // en,
    en: enlang,
}
export const langType = {
    zh_CN: 'zh-CN',
    en: 'en',
}
export default lang;

