<template>
  <div class="empty-project-container">
    <t-image src="/welcome-img.png" class="welcome-img" />
    <t-image src="/welcome-text.svg" class="welcome-text" />
    <div class="description">
      {{ pageStatus.lang.emptyProject.description }}
    </div>
  </div>
</template>

<script setup>
import { pageStatusStore } from "@/store";

const pageStatus = pageStatusStore();
</script>
<style lang='less' scoped>
.empty-project-container {
  height: 100%;
  .flex-align-center();
  flex-direction: column;
  // justify-content: center;
  padding-top: 20vh;
  background: var(--td-bg-color-container);
  margin: 10px 10px 10px 0px;
  border-radius: 10px;
  .welcome-img {
    width: 283px;
    height: 298px;
    // width: 18vw;
    // height: 33vh;
  }
  .welcome-text {
    width: 253px;
    height: 42px;
  }
  :deep(.t-image__wrapper) {
    background: rgba(0, 0, 0, 0);
  }
  .description {
    color: var(--color-text-icon-secondary-default, rgba(255, 255, 255, 0.6));
    text-align: center;
    .font-pingfang(@fz:16px,@lh:24px);
  }
}
</style>
