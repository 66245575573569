<template>
  <t-config-provider :global-config="globalConfig">
    <t-layout v-if="showContent" class="layout-container">
      <t-header>
        <t-head-menu value="item1" height="120px">
          <template #logo>
            <img height="28" class="logo" :src="logoUrl" />
          </template>

          <template #operations>
            <t-select
              v-if="!showEmptyProject"
              v-model="value2"
              :autoWidth="true"
              :options="options2"
              @change="handleProgramChange"
            />
            <!-- <t-button :class="`${BUTTON_CLASS.remind}`">
            <template #icon>
              <icon :name="`${ICON_TYPE.remind}`" :url="iconUrl" />
              <icon :name="`${ICON_TYPE.badge}`" :url="iconUrl" />
            </template>
          </t-button> -->
            <t-dropdown>
              <t-button :class="`${BUTTON_CLASS.user}`" class="avatar">
                <template #icon>
                  <icon :name="`${ICON_TYPE.user}`" :url="iconUrl" />
                </template>
              </t-button>
              <t-dropdown-menu>
                <t-dropdown-item
                  v-for="dropdownItem in dropdownOptions"
                  :key="dropdownItem.value"
                  :value="dropdownItem.value"
                  :theme="dropdownItem.theme"
                  :divider="dropdownItem.divider"
                  @click="dropdownItem.onClick"
                >
                  <div :class="dropdownItem.class || 'dropdown-item-container'">
                    <icon
                      :name="dropdownItem.icon"
                      :url="iconUrl"
                      class="dropdown-item-icon"
                    /><span>{{ dropdownItem.content }}</span>
                  </div>
                </t-dropdown-item>
              </t-dropdown-menu>
            </t-dropdown>
          </template>
        </t-head-menu>
      </t-header>
      <empty-project v-if="showEmptyProject" />
      <t-layout key="side" v-else>
        <t-aside><layout-side-nav /></t-aside>
        <t-layout>
          <t-content class="meta-layout-content"><layout-content /></t-content>
        </t-layout>
      </t-layout>
      <t-dialog
        v-model:visible="userCenterDialogVisible"
        :width="800"
        :footer="false"
        :header="pageStatus.lang.userCenter.title"
      >
        <template #body>
          <user-center
            v-if="userCenterDialogVisible"
            :show-project="!showEmptyProject"
            :user-info="userInfo"
          />
        </template>
      </t-dialog>
      <t-dialog
        v-model:visible="logoutDialogVisible"
        :header="pageStatus.lang.logout.confirmLogout"
        :on-confirm="logoutDialogConfirm"
      />
      <lang-dialog
        v-model:visible="langDialogVisible"
        :language="language"
        :user-info="userInfo"
        @updatelang="handleUpdateLang"
      />
      <project-info-dialog v-model:visible="projectInfoDialogVisible" />
    </t-layout>
  </t-config-provider>
</template>

<script setup>
import { ref, watch, computed } from "vue";
import { Icon } from "tdesign-icons-vue-next";
import enConfig from "tdesign-vue-next/es/locale/en_US";
import zhConfig from "tdesign-vue-next/es/locale/zh_CN";
import { useRoute } from "vue-router";

import "../style/layout.less";
import { pageStatusStore } from "@/store";
import router from "@/router";
import { getProjectList, getProjectUserSetting } from "@/api/project";
import { BUTTON_CLASS, ICON_TYPE } from "@/utils/buttonStyle";
import {
  sendProjectToUEOnChange,
  sendProjectToUEOnLogin,
} from "@/api/UEEditor";
import { addToken, deleteStorage } from "@/utils/localStorage";
import { _TOKEN, logout } from "@/api/login";
import { getUserInfo } from "@/api/user";
import { lang, langType } from "@/config/lang/index";
import { LOGO_URL } from "@/config/global";
import { updateStoreRoleList } from "@/api/pageStatusUser";

import LayoutContent from "./components/LayoutContent.vue";
import LayoutSideNav from "./components/LayoutSideNav.vue";
import UserCenter from "@/pages/dashboard/user/UserCenter.vue";
import EmptyProject from "./components/EmptyProject.vue";
import langDialog from "./components/langDialog.vue";
import ProjectInfoDialog from "./components/ProjectInfoDialog.vue";

const iconUrl = "/icon/icon.js";
const pageStatus = pageStatusStore();
const route = useRoute();

const options2 = ref([{ label: "", value: "" }]);
const value2 = ref(options2.value[0].value);
pageStatus.project = options2.value[0];
const showContent = ref(false);
const logoUrl = ref(LOGO_URL);
const showEmptyProject = ref(false);
const projectUserSetting = ref(null);

const language = ref(langType.zh_CN);
pageStatus.lang = lang.zh_CN;
const globalConfig = ref(zhConfig);
const PROJECT_CHANGE_TYPE = {
  login: "login", // 首次赋值
  change: "change", // 切换项目
  check: "check", // 校验是否过期
};

const userInfo = computed(() => pageStatus.userInfo);
const dropdownOptions = computed(() => {
  let options = [];
  // 无可用项目时只显示退出登录
  if (!showEmptyProject.value) {
    options = options.concat([
      {
        content: pageStatus.userInfo.username || "",
        value: "realname",
        icon: ICON_TYPE.user,
        theme: "default",
        divider: true,
        class: "dropdown-item-realname",
      },
      {
        content: pageStatus.lang.userDropdown.personCenter,
        value: "user",
        icon: ICON_TYPE.user_line,
        theme: "default",
        onClick: () => {
          userCenterDialogVisible.value = true;
        },
      },
      {
        content: pageStatus.lang.userDropdown.authManager,
        value: "role",
        icon: ICON_TYPE.lock,
        theme: "default",
        onClick: () => {
          router.push("/dashboard/user/manager");
        },
      },
      {
        content: pageStatus.lang.userDropdown.setting,
        value: "setting",
        icon: ICON_TYPE.lock,
        theme: "default",

        onClick: () => {
          router.push("/dashboard/setting");
        },
      },
      {
        content: pageStatus.lang.userDropdown.projectInfo,
        value: "projectinfo",
        icon: ICON_TYPE.project_info,
        theme: "default",
        divider: true,
        onClick: () => {
          projectInfoDialogVisible.value = true;
        },
      },
      // {
      //   content: pageStatus.lang.userDropdown.lang,
      //   value: "lang",
      //   icon: ICON_TYPE.lock,
      //   theme: "default",
      //   divider: true,
      //   onClick: () => {
      //     langDialogVisible.value = true;
      //   },
      // },
    ]);
  }
  options.push({
    content: pageStatus.lang.userDropdown.logout,
    value: "logout",
    icon: ICON_TYPE.exit_large,
    theme: "default",
    onClick: () => {
      logoutDialogVisible.value = true;
    },
  });
  return options;
});

const userCenterDialogVisible = ref(false);
const logoutDialogVisible = ref(false);
const langDialogVisible = ref(false);
const projectInfoDialogVisible = ref(false);

watch(
  () => route.path,
  () => {
    checkProject(value2.value, PROJECT_CHANGE_TYPE.check);
  }
);
// 同步更新页面logo
watch(
  () => pageStatus.project,
  (project) => {
    if (project.logoUrl && project.logoState === 2) {
      logoUrl.value = addToken(project.logoUrl);
    } else {
      logoUrl.value = LOGO_URL;
    }
  },
  {
    deep: true,
  }
);

watch(
  () => pageStatus.project,
  (project) => {
    getUserInfo({ project_id: pageStatus.project.value }).then((result) => {
      pageStatus.userInfo = result.data || {};
    });
    updateStoreRoleList(pageStatus.project.value).then((result) => {
      pageStatus.roleList = result;
    });

    if (
      projectUserSetting.value !== null &&
      projectUserSetting.value[project.value]
    ) {
      language.value = projectUserSetting.value[project.value].language;
    } else {
      // 切换项目时，没有配置语言的默认为中文
      language.value = langType.zh_CN;
    }
  }
);

// 切换语言
watch(
  () => language.value,
  (v) => {
    pageStatus.lang = v === langType.zh_CN ? lang.zh_CN : lang.en;
    globalConfig.value = v === langType.zh_CN ? zhConfig : enConfig;
  }
);
const checkProject = async (value, type) => {
  const { data } = await getProjectList();
  if (!data) {
    return;
  }
  showContent.value = true;
  let options =
    data?.data
      ?.map((item) => ({
        ...item,
        label: item.name,
        value: item.id,
        // logo状态 1：默认 ，2:启动自定义，3：不显示(未启用)
        logoState: item.logo_state,
        logoUrl: item.logo_url,
        // publish 环境类型,1:无, 2:云5
        envType: item.publish_env?.env_type,
        //（公有云）有效期，-1 :永久有效, >= 0 ,设置初始有效期天数,0代表已过期,(-1时ValidAt/ExpiredAt无效)
        validPeriod: item.valid_period,
        // 过期时间
        expiredAt: item.expired_at,
        // 场景数量限制，-1无限制
        sceneLimit: item.scene_limit,
        // 用户数量限制，-1 无限制
        userLimit: item.user_limit,
        // 云渲染地址/体验地址
        renderUrl: item.render_url,
        // Token
        token: item.secret_key,
        // online_mode： 单机版'single'、多人模式'multi'
        onlineMode: item.online_mode,
      }))
      .filter(
        // 不显示过期项目
        (item) =>
          item.validPeriod === -1 || new Date(item.expiredAt) - new Date() > 0
      ) ?? [];
  if (options.length === 0) {
    // 进入无项目信息页面
    showEmptyProject.value = true;
    return;
  }
  options2.value = options;
  if (type === PROJECT_CHANGE_TYPE.login) {
    checkDefaultProject(options);
    sendProjectToUEOnLogin(pageStatus.project.value, pageStatus.project.label);
    return;
  }
  const item = options.find((item) => {
    return item.value === value;
  });
  if (
    (item && type === PROJECT_CHANGE_TYPE.change) ||
    JSON.stringify(item) !== JSON.stringify(pageStatus.project)
  ) {
    // 项目未过期且是切换项目或者项目信息有变化时执行
    pageStatus.project = item;
  }
  if (!item) {
    // 之前项目过期之后执行
    checkDefaultProject(options);
  }
  sendProjectToUEOnChange(pageStatus.project.value, pageStatus.project.label);
};
const checkDefaultProject = (options) => {
  value2.value = options[0].value;
  pageStatus.project = options[0];
};

checkProject(null, PROJECT_CHANGE_TYPE.login);
const updateLang = () => {
  getProjectUserSetting().then((result) => {
    console.info(result);
    projectUserSetting.value = result.data?.proj_setting;
    // getProjectList和getProjectUserSetting并行发起，不确定谁先执行完，
    // 若先获取的项目信息，这里直接赋值即可，若先获取的了语言信息，则监听项目变化来同步
    // projectUserSetting.value[pageStatus.project.value] 可能用户有多个项目权限，部分有语言设置信息，部分没有
    if (
      pageStatus.project.value !== "" &&
      projectUserSetting.value !== null &&
      projectUserSetting.value[pageStatus.project.value]
    ) {
      language.value =
        projectUserSetting.value[pageStatus.project.value].language;
    }
  });
};
updateLang();

const handleProgramChange = async (data) => {
  checkProject(data, PROJECT_CHANGE_TYPE.change);
};
const logoutDialogConfirm = async () => {
  logoutDialogVisible.value = false;
  try {
    await logout();
    deleteStorage(_TOKEN);
    router.replace("/login");
  } catch (error) {
    console.log(error);
  }
};
const handleUpdateLang = () => {
  updateLang();
};
</script>

<style lang="less" scoped>
.layout-container {
  height: 100%;
  // 兼容UE插件panel没有100%布局的问题
  .meta-layout-content {
    height: 100%;
  }
  .t-layout__sider {
    margin: 10px;
    border-radius: 10px;
  }
  :deep(.t-head-menu) {
    padding: 12px 24px;
    .t-head-menu__inner {
      height: 30px;
      align-items: center;
    }
  }
}
.logo-text {
  margin: 0px 8px;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  .t-divider--vertical {
    margin: 0px 8px;
  }
}
.t-select__wrap {
  width: auto;
}
.avatar {
  margin-left: 18px;
}
.dropdown-item-container {
  .flex-basic(flex-start,center);
  .dropdown-item-icon {
    margin-right: 6px;
  }
}
.dropdown-item-realname {
  flex-direction: column;
  .flex-align-center();
  span {
    color: rgba(255, 255, 255, 0.6);
    text-align: center;
    .text-overflow-ellipsis(100px);
  }
  .dropdown-item-icon {
    margin-bottom: 12px;
    padding: 5px;
    background-color: rgba(255, 255, 255, 0.24);
    border-radius: 50px;
  }
}
</style>
