<template>
  <t-button class="copy-btn" variant="text" @click="handleCopyClick">
    <template #icon>
      <icon :name="`${ICON_TYPE.copy_fill}`" :url="iconUrl" />
    </template>
    <template v-if="needText" #content>
      {{ text ? text : pageStatus.lang.dashboard.publishManager.copyLink }}
    </template>
  </t-button>
</template>

<script>
export default {
  name: "CopyComponent",
};
</script>

<script setup>
import { MessagePlugin } from "tdesign-vue-next";
import { Icon } from "tdesign-icons-vue-next";

import { pageStatusStore } from "@/store";
import { ICON_TYPE } from "@/utils/buttonStyle";
import { copyToClipboard } from "@/utils/common";

const props = defineProps({
  str: {
    type: String,
    default: "",
  },
  needText: {
    type: Boolean,
    default: false,
  },
  text: {
    type: String,
    default: "",
  },
});

const iconUrl = "/icon/icon.js";
const pageStatus = pageStatusStore();

const handleCopyClick = () => {
  copyToClipboard(
    props.str,
    () => MessagePlugin.success(pageStatus.lang.message.copySuccess),
    (e) => {
      MessagePlugin.error(pageStatus.lang.message.copyFail);
      console.error(e);
    }
  );
};
</script>

<style lang="less" scoped>
.copy-btn {
  :deep(.t-button__text) {
    color: #4e81ff;
    margin-left: 6px !important;
  }
}
</style>