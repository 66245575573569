export const BUTTON_CLASS = {
    nav:'meta-icon navigation-btn',
    download:'meta-icon navigation-btn',// 与navigation相同样式
    add:'meta-icon add-btn',
    exit:'meta-icon exit-btn',
    setting:'meta-icon setting-btn',
    edit:'meta-icon edit-btn',
    delete:'meta-icon delete-btn',
    more:'meta-icon more-btn',
    close:'meta-icon close-btn',
    user:'meta-icon user-btn',
    remind:'meta-icon remind-btn',
    open_layer:'meta-icon edit-btn',// 与edit相同样式
    open_layer_circle:'meta-icon circle-btn',// 圆形样式
}
export const ICON_TYPE = {
    nav:'meta-icon-navigation',
    download:'meta-icon-download',
    add:'meta-icon-add',
    joint:'meta-icon-joint',
    exit:'meta-icon-exit',
    exit_large:'meta-icon-exit-large',
    setting:'meta-icon-setting',
    edit:'meta-icon-edit',
    delete:'meta-icon-delete',
    more:'meta-icon-more',
    close:'meta-icon-close',
    user:'meta-icon-user',
    remind:'meta-icon-remind',
    badge:'meta-icon-badge',
    task:'meta-icon-task',
    approve:'meta-icon-approve',
    model:'meta-icon-model',
    npc:'meta-icon-npc',
    apparel:'meta-icon-apparel',
    texture:'meta-icon-texture',
    vfx:'meta-icon-vfx',
    point:'meta-icon-point',
    download:'meta-icon-download',
    download_line:'meta-icon-download-line',
    open_layer:'meta-icon-open-layer',
    user_line:'meta-icon-user-line',
    lock:'meta-icon-lock',
    fail:'meta-icon-fail',
    search:'meta-icon-search',
    layer:'meta-icon-layer',
    folder_open:'meta-icon-folder-open',
    folder_close:'meta-icon-folder-close',
    down:'meta-icon-down',
    copy:'meta-icon-copy',
    copy_fill:'meta-icon-copy-fill',
    back:'meta-icon-back',
    enter:'meta-icon-enter',
    triangle_down:'meta-icon-triangle-down',
    triangle_right:'meta-icon-triangle-right',
    share:'meta-icon-share',
    add_large:'meta-icon-add-large',
    minus:'meta-icon-minus',
    refresh:'meta-icon-refresh',
    warn:'meta-icon-warn',
    info:'meta-icon-info',
    success:'meta-icon-success',
    view_on:'meta-icon-view-on',
    view_off:'meta-icon-view-off',
    project_info:'meta-icon-project-info',
    login_logo:'meta-icon-logo',
    account:'meta-icon-account',
    login_lock:'meta-icon-login-lock',
    secure:'meta-icon-secure',
    left:'meta-icon-left',
    eye_y:'meta-icon-eye-y',
    eye_n:'meta-icon-eye-n',
    drag:'meta-icon-drag',
    dialogWarn:'meta-icon-dialogWarn'
}